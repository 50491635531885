import Link from "next/link";
import React from "react";

const Copyright = () => {
  return (
    <>
      <div className="copyright-area copyright-style-one">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-8 col-sm-12 col-12">
              <div className="copyright-left">
                <ul className="ft-menu link-hover">
                  <li>
                    <Link href="https://roninservers.com/privacy.php">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link href="https://roninservers.com/terms.php">Terms And Condition</Link>
                  </li>
                  <li>
                    <Link href="mailto:support@roninservers.com">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 col-12">
              <div className="copyright-right text-center text-lg-end">
                <p className="copyright-text">
                  Copyright © 2024 Ronin Servers Pty Ltd - ABN 16 660 383 960<br/>
                  Ronin Servers Pty Ltd is a subsidary of 
                  <Link
                    href="https://ronintechnologies.com.au/"
                    className="btn-read-more ps-2"
                  >
                    <span>Ronin Technologies Pty Ltd</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Copyright;
